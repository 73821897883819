// @ts-check
import { createHttpServiceConfig } from '@/services/httpFactories';
import { MONITORING_SERVICE_ENDPOINT, LOG_RESOURCE_PATH } from './monitoringServiceConstants';

const monitoringServiceConfig = createHttpServiceConfig(MONITORING_SERVICE_ENDPOINT, LOG_RESOURCE_PATH);

/**
 * @param {import('@/services/monitoring-service/monitoringServiceTypes').ILogPayload} data 
 */
export const createMonitoringLogService = (data) => monitoringServiceConfig.http({
    method: 'POST',
    data
});
