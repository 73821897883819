import { render, staticRenderFns } from "./FooterLoader.vue?vue&type=template&id=2a0c8814"
import script from "./FooterLoader.vue?vue&type=script&lang=js"
export * from "./FooterLoader.vue?vue&type=script&lang=js"
import style0 from "./FooterLoader.vue?vue&type=style&index=0&id=2a0c8814&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports