<template>
  <div class="app-footer-loader">
    <div class="columns mb-3">
      <div
        class="column is-6 pl-5"
      >
        <b-skeleton
          height="2.5rem"
          width="4rem"
        />
      </div>
      <div
        class="column is-6"
      >
        <div
          class="columns"
        >
          <div
            v-for="(_, index) in 3"
            :key="'nav-item-loader' + index"
            class="column is-3"
          >
            <b-skeleton 
              height="1.5rem"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="is-flex is-justify-content-center">
      <div>
        <b-skeleton
          height="1.5rem"
          width="15rem"
        />
      </div>
    </div>
  </div>
</template>
  
<script >
const __sfc_main = {};
export default __sfc_main;
</script>
  
<style lang="scss">
.app-header-loader {
  .app-header-loader-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
