/**
 * unescape HTML element
 * @param {string} str
 */
export const unescapeHtml = (str) => {
    return str ? str.replace(/&quot;/g, '"')
        .replace(/&#39;/g, '\'')
        .replace(/&#x3A;/g, ':')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&') : '';
};

/**
 * @param {string} html 
 */
export const escapeHtml = (html) => {
    const div = document.createElement('div');
    div.appendChild(document.createTextNode(html));
    return div.innerHTML;
};

/**
 * Promise wrapper for requestAnimationFrame
 */
export const nextFrame = () => new Promise(requestAnimationFrame);

/**
 * Promise wrapper for setTimeOut
 * @param {number} time
 */
export const delay = (time = 1000) => new Promise((resolve) => { 
    setTimeout(() => {
        resolve(true);
    }, time);
});
