<template>
  <div>
    <div
      v-for="(loader, lIndex) in loaderTemplateMeta"
      :key="'skeleton-loader' + lIndex"
      class="loader-container"
    >
      <!-- Loader for field's label -->
      <b-skeleton 
        v-if="!['next-btn', 'back-btn'].includes(loader.type)"
        height="1.5rem"
        width="10rem"
      />
      <div class="mb-3" />
      <!-- Field's loader -->
      <CheckboxLoader v-if="loader.type === 'checkbox'" />
      <RadioboxLoader v-if="loader.type === 'radiobox'" />
      <b-skeleton 
        v-if="loader.type === 'input'"
        height="3rem"
      />
      <b-skeleton 
        v-if="loader.type === 'text-area'"
        height="10rem"
      />
    </div>
  </div>
</template>

<script >
import CheckboxLoader from './CheckboxLoader.vue';
import RadioboxLoader from './RadioboxLoader.vue';
const __sfc_main = {};
__sfc_main.props = {
  loaderTemplateMeta: {
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  CheckboxLoader,
  RadioboxLoader
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.loader-container {
  margin-bottom: 2rem;
  .b-skeleton {
    &:first-child {
      margin-right: 2rem;
    }
    margin-top: 0;
  }
}
</style>
