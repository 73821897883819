<template>
  <div class="radiobox-loader">
    <div
      v-for="(_, lIndex) in lines"
      :key="'radiobox-line' + lIndex"
      class="is-flex is-align-items-center mb-4"
    >
      <b-skeleton
        circle
        height="2rem"
        width="2rem"
      />
      <b-skeleton height="2rem" />
    </div>
  </div>
</template>
  
<script >
const __sfc_main = {};
__sfc_main.props = {
  lines: {
    type: Number,
    default: 2
  }
};
export default __sfc_main;
</script>

<style lang="scss">
.radiobox-loader {
  .b-skeleton {
    &:first-child {
      width: auto;
    }
  }
}
</style>
  