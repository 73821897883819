<template>
  <div class="app-header-loader">
    <div class="is-flex is-align-items-center is-justify-content-space-between p-2">
      <div>
        <b-skeleton 
          height="2.5rem"
          width="4rem"
        />
      </div>
      <div class="is-flex">
        <div
          v-for="(_, index) in 3"
          :key="'nav-item-loader' + index"
          class="mr-3"
        >
          <b-skeleton 
            height="1.25rem"
            width="5rem"
          />
        </div>
        <b-skeleton
          circle
          height="1.5rem"
          width="1.5rem"
        />
      </div>
    </div>
  </div>
</template>

<script >
const __sfc_main = {};
export default __sfc_main;
</script>

<style lang="scss">
.app-header-loader {
    .app-header-loader-container {
        display: flex;
        justify-content: space-between;
    }
}
</style>
