// @ts-check
import { pinia } from '@/plugins/pinia';
import { useVariableStore } from '@/store/variableStore';
import { useWorkflowStore } from '@/store/workflowStore';
import { getExpressionParser, getVariableParser } from '@/lib/nuclicore-core/esm';

export const createVariableSetter = () => {
    const expressionParser = getExpressionParser();
    const variableParser = getVariableParser();
    const workflowStore = useWorkflowStore(pinia);
    const variableStore = useVariableStore(pinia);

    const execute = async (node) => {
        const nodeInfo = node.info.data;
        
        const variableReference = variableParser.extractReferenceId(nodeInfo.variableReference);
        const { value } = await expressionParser.parse(nodeInfo.variableValue, 'unwrap');
        if (variableReference in workflowStore.workflowsVariables) {
            workflowStore.updateWorkflowVariable(variableReference, {
                value
            });
        } else if (variableReference in variableStore.variables) {
            const id = { value: variableReference, type: 'variable'};
            variableStore.updateVariableById(id, {
                value
            });
        }
    };

    return {
        execute
    };
};
