import { getExpressionParser, createJsonBuilder } from '@/lib/nuclicore-core/esm';
import { pinia } from '@/plugins/pinia';
import { useWorkflowStore } from '@/store/workflowStore';

//  import { readFile } from 'fs/promises';
// import { getType } from 'mime';

export const createDataMapper = () => {

    const expressionParser = getExpressionParser();
    const jsonBuilder = createJsonBuilder();
    const workflowStore = useWorkflowStore(pinia);

    const buildJSON = async (structuredJson, properties) => {
        return await jsonBuilder.buildJSON(structuredJson, properties);
    };

    const buildJSONArray =  async (propArray, value) => {
        return await jsonBuilder.buildJSONArray(propArray, value);
    };

    const buildVariables = async (variables) => {
        const parsedVariables = [];

        const primitiveTypes = ['String', 'Number', 'Boolean'];
        for (const variable of variables) {
            if (variable.type === 'Array') {
                let variableValue = '';
                if (variable.dynamic) {
                    variableValue = (await expressionParser.parse(variable.value, 'strip')).value;
                } else {
                    variableValue = await buildJSONArray([], variable.value);
                    variableValue = JSON.stringify(variableValue);
                    variableValue = `{${variableValue.substring(1, variableValue.length - 1)}}`;
                }

                parsedVariables.push({
                    name: variable.reference,
                    value: variableValue
                });
            } else if (variable.type === 'Object') {
                let variableValue = await buildJSON({}, [variable]);
                variableValue = JSON.stringify(variableValue);

                parsedVariables.push({
                    name: variable.reference,
                    value: variableValue
                });
            }
            else if (primitiveTypes.includes(variable.type)) {
                let { value: variableValue } = await expressionParser.parse(variable.value, 'unwrap');
                if (variable.type === 'Boolean') {
                    const booleanValue = typeof variableValue === 'string' ? variableValue === 'true' : !!variableValue;
                    variableValue = `_BOOLEAN(${booleanValue})`;
                }

                parsedVariables.push({
                    name: variable.reference,
                    value: variableValue
                });
            }
        }

        return parsedVariables;
    };

    // @TO-Do's: may not be needed for a browser environment
    /**
     * @param {array} rawData
     */
    // const parseFormData = async (rawData) => {
    //     // Only processing raw values here and returning the results in a plain object
    //     const parsedData = {};
    //     for (const property of rawData) {
    //         parsedData[property.name] = {};
    //         parsedData[property.name].type = property.type || 'String';
    //         if (property.type === 'String') {
    //             parsedData[property.name].value = (await expressionParser.parse(property.value, 'unwrap')).value;
    //         }
    //         else if (property.type === 'File') {
    //             parsedData[property.name].value = property.value;
    //         }
    //     }
    //     return parsedData;
    // }

    // @TO-Do's: Need different implementation for a browser environment
    // const buildFormData = async (node, sourceData) => {
    //     try {
    //         const formData = new FormData();
    //         for (const [key, data] of Object.entries(sourceData || {})) {
    //             let value = null;
    //             if (data.type === 'String') {
    //                 value = data.value;
    //                 formData.append(key, value);
    //             } else if (data.type === 'File') {
    //                 const [userDocs, systemGeneratedDocs] = await Promise.all([
    //                     this.session_repository.getDocumentsBySessionAndField(session, node.data.module_id, data.value), 
    //                     this.session_repository.getDocumentBySession(session, node.data.module_id, data.value)
    //                 ]);
    //                 const docs = [
    //                     ...(userDocs || []),
    //                     ...(systemGeneratedDocs || [])
    //                 ];
    //                 if (docs.length) {
    //                     const files = docs.map(async (file) => {
    //                         const fileContent = await readFile(file.path);
    //                         return {
    //                             name: file.name,
    //                             size: fileContent.length,
    //                             mimeType: getType(file.path),
    //                             content: fileContent
    //                         };
    //                     });
    //                     value = await Promise.all(files);
    //                     value.forEach(file => {
    //                         formData.append(key, file.content, {
    //                             filename: file.name,
    //                             contentType: file.mimeType,
    //                             knownLength: file.size
    //                         });
    //                     });
    //                 }
    //             }
    //         };

    //         return formData;
    //     } catch (err) {
    //         console.error(err);
    //         this.monitoring_service.sendSlackLog(err, {
    //             appId: node.data.application_id,
    //             moduleId: node.data.module_id,
    //             environment: environmentName,
    //             session
    //         });
    //         return null;
    //     }
    // }

    const execute = async (node) => {
        try {
            const nodeInfo = node.info.data;
            if (nodeInfo.dataType === 'JSON') {
                const structuredJson = await buildJSON({}, nodeInfo.fields.json);
                const value = JSON.stringify(structuredJson || {});
                workflowStore.updateWorkflowVariable(`${node.nodeId}JSON`, {
                    value
                });

                return {
                    level: 'info',
                    data: {
                        action: 'Building a new data set',
                        title: 'Successfuly created JSON',
                        message: value
                    }
                };
            } else if (nodeInfo.dataType === 'Variables') {
                const variables = await buildVariables(nodeInfo.fields.variables);
                variables.forEach(variable => {
                    workflowStore.updateWorkflowVariable(variable.name, {
                        value: variable.value
                    });
                });
                return {
                    level: 'info',
                    data: {
                        action: 'Building a new data set',
                        title: 'Built Variables',
                        message: JSON.stringify(variables)
                    }
                };
            } 

            // @TO-DO's: support for form data to be implemented

            // else if (nodeInfo.dataType === 'FormData') {
            // const formData = {};
            // const formData = await parseFormData(nodeInfo.fields.formData || []);

            // session_values.push([session, node.node_id + 'FormData', formData]);
            // await this.session_repository.insertCurrentValues(session_values);

            // return {
            //     status: 1008,
            //     data: {
            //         action: 'Building a new data set',
            //         message: 'New data set built.',
            //         meta: formData
            //     },
            // };
            // }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        execute
    };
};
